.form__group {
    display: flex;
    align-items: center;
  
    & > * {
      flex-basis: 100%;
    }
  
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  