.main-padding {
  padding: 1rem;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.height-15 {
  height: 15px;
}

.hidden {
  display: none;
}
