.row_value {
  padding: 0.5em 1em;
  display: inline;
}

.hovered {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}

.edit_icon {
  margin-left: 10px;
  opacity: 0.5;
}
