.gsc-control-cse {
  background-color: transparent !important;
  border: none !important;
}

.gsc-expansionArea,
.gsc-resultsRoot,
.gsc-resultsbox-visible,
.gsc-above-wrapper-area,
.gsc-result {
  border: none !important;
  background-color: transparent !important;
}

.gs-bidi-start-align {
  color: white !important;
}

a.gs-title {
  color: #fd7afe !important;

  b {
    color: #fd7afe !important;
  }
}

.gsc-input {
  background-color: #2c3139 !important;
  padding: 0 !important;
  font-size: 11pt !important;
  border-radius: 3px !important;
  flex: 1 !important;
}

.gsc-input-box {
  border-radius: 3px !important;
  border: 1px solid black !important;
  background: none !important;
}

td.gsc-search-button {
  background-color: #3f4753 !important;
  border: 1px solid black !important;
  border-radius: 3px !important;
  cursor: pointer;
  width: max-content !important;
}

.gsc-search-button-v2 {
  background-color: #3f4753 !important;
  border-radius: 3px !important;
  border: none !important;
  cursor: pointer;
}

.gsc-search-box tbody tr {
  display: flex;
  width: 100% !important;
}
