.container {
    max-height: 90vh; 
    overflow-y: auto; 
    padding: 1rem;

    .section_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }

    .field_list { 
        max-height: 180px;
        margin-bottom: 1rem;

        &__scrollable {
            overflow-y: scroll;
        }

        .field_item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.5rem;
            margin: 0.5rem 0;
            
            &__clickable {
                cursor: pointer;
            }

            &__selected {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.dialog_footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem 1rem;
}