.select_country_btn {
  width: 100%;
  border-bottom: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(143, 153, 168, 0.15);
  }
}
