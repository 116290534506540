.text {
  white-space: nowrap;
  font-weight: 600;
  margin-bottom: 5px;
}

.text_wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}

.tag {
  margin: 3px;
}

.tag:hover {
  cursor: pointer;
}

.popover {
  overflow-y: auto;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popover::-webkit-scrollbar {
  width: 5px;
}

.popover::-webkit-scrollbar-track {
  background: transparent;
}

.popover_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3px;
  padding: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.popover_content::-webkit-scrollbar {
  width: 5px;
}

.popover_content::-webkit-scrollbar-track {
  background: transparent;
}

.add_button {
  margin-top: 10px;
}

.editables {
  display: grid;
  gap: 10px;
}
