@import "~@stockifi/shared/src/styles/variables.scss";

.tab_content {
  background-color: $dark-gray5;
  width: 100%;
  padding: 10px;
  border: 0.25px solid $black;
  margin-top: 8px;
  min-height: 200px;
  max-height: 64vh;
  overflow-y: auto;
  margin-left: 15px;
}

.tab_content_loading {
  background-color: $dark-gray5;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 0.25px solid $black;
  margin-top: 8px;
  min-height: 200px;
  max-height: 64vh;
  overflow-y: auto;
}

.tab_content::-webkit-scrollbar {
  width: 5px;
}

.tab_content::-webkit-scrollbar-track {
  background: transparent;
}
