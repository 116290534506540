.container {
  white-space: nowrap;
  overflow-y: hidden;
}
.container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.container::-webkit-scrollbar-track {
  background: transparent;
}

.table {
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
  border-top: 1px solid #666d76;
}

.th,
.td {
  text-align: start;
  padding: 15px;
  border-bottom: 1px solid #666d76;
}

.th {
  background-color: #383e47;
  top: 0;
  cursor: pointer;
}

.td {
  background-color: #444c57;
}

.th:nth-child(1),
.td:nth-child(1) {
  position: sticky;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  left: 0;
  z-index: 10;
}

.th:nth-child(2),
.td:nth-child(2) {
  position: sticky;
  left: 50px;
  outline: 1px solid #666d76;
  z-index: 10;
}

.td:nth-child(1),
.td.actions {
  background-color: #383e47;
}

.tr:hover .td:nth-child(1),
.tr:hover .td:nth-child(2) {
  background-color: #525b69;
}

.tr:hover .td {
  background-color: #525b69;
}

