.content_wrapper {
  padding: 30px;
  display: none;
  -webkit-animation: fadein-content 436ms;
  -moz-animation: fadein-content 436ms;
  animation: fadein-content 436ms;
}
.content_wrapper.show {
  display: block;
}

.loading_indicator {
  background-color: rgba(245, 245, 245, 0.062745098);
  width: 100%;
  height: 100%;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 8;
}
.loading_indicator.avail {
  opacity: 0;
}
.loading_indicator .first,
.loading_indicator .sec {
  background-color: #bb86fc;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
}
.loading_indicator .first {
  -webkit-animation: first-indicator 2s linear infinite;
  -moz-animation: first-indicator 2s linear infinite;
  animation: first-indicator 2s linear infinite;
}
.loading_indicator .sec {
  -webkit-animation: second-indicator 2s linear infinite;
  -moz-animation: second-indicator 2s linear infinite;
  animation: second-indicator 2s linear infinite;
}

.frame {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-animation: fadein 436ms;
  -moz-animation: fadein 436ms;
  animation: fadein 436ms;
  height: 100%;
  z-index: 7;
}

.head {
  background-color: #fac6fc;
  height: 3px;
  overflow: hidden;
  position: relative;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes first-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }
  25% {
    transform: translate(0) scaleX(0.5);
  }
  50% {
    transform: translate(25%) scaleX(0.75);
  }
  75% {
    transform: translate(100%) scaleX(0);
  }
  100% {
    transform: translate(100%) scaleX(0);
  }
}
@keyframes second-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }
  60% {
    transform: translate(0) scaleX(0);
  }
  80% {
    transform: translate(0) scaleX(0.6);
  }
  100% {
    transform: translate(100%) scaleX(0.1);
  }
}

