.notification__popover {
  padding: 1em;
  width: 300px;
  position: relative;
  max-height: 400px;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
  }
}

.notification__card {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1em;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  & > *:nth-child(1) {
    flex-basis: 90%;
  }

  & > *:nth-child(2) {
    flex-basis: 10%;
  }
}

.notification__content__message {
  word-break: break-all;
}

.dismiss__btn__wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dismiss__btn {
  cursor: pointer;
}
