.form__group {
  display: flex;
  align-items: center;

  & > * {
    flex-basis: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.embed__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  & > *:nth-child(1) {
    flex-basis: 20%;
    margin-right: 0.5em;
  }

  & > *:nth-child(2) {
    flex-basis: 80%;
    margin-left: 0.5em;
  }
}

.embed__header {
  display: flex;
  align-items: center;

  & > *:nth-child(1) {
    flex-basis: 20%;
    margin-right: 0.5em;
  }

  & > *:nth-child(2) {
    flex-basis: 20%;
    margin-left: 0.5em;
  }
}
