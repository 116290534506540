.highlightColor {
  tr {
    padding-bottom: 1rem;
    td:not(:last-child) {
      padding: 10px 20px 20px 0;
    }

    td:last-child {
      padding-bottom: 16px;
    }
  }
}
