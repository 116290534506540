@import './variables.scss';

.rdp{
  --rdp-cell-size:40px;
  --rdp-caption-font-size:18px;
  --rdp-accent-color:#0000ff;
  --rdp-background-color:#e7edff;
  --rdp-accent-color-dark:#3003e1;
  --rdp-background-color-dark:#180270;
  --rdp-outline:2px solid var(--rdp-accent-color);
  --rdp-outline-selected:3px solid var(--rdp-accent-color);
  margin:1em;
}
.rdp-vhidden{
  box-sizing:border-box;
  padding:0;
  margin:0;
  background:transparent;
  border:0;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  position:absolute !important;
  top:0;
  width:1px !important;
  height:1px !important;
  padding:0 !important;
  overflow:hidden !important;
  clip:rect(1px, 1px, 1px, 1px) !important;
  border:0 !important;
}
.rdp-button_reset{
  appearance:none;
  position:relative;
  margin:0;
  padding:0;
  cursor:default;
  color:inherit;
  background:none;
  font:inherit;
  -moz-appearance:none;
  -webkit-appearance:none;
}

.rdp-button_reset:focus-visible{
  outline:none;
}

.rdp-button{
  border:2px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected){
  opacity:0.25;
}

.rdp-button:not([disabled]){
  cursor:pointer;
}

.rdp-button:focus-visible:not([disabled]){
  color:inherit;
  background-color:var(--rdp-background-color);
  border:var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected){
  background-color:var(--rdp-background-color);
}

.rdp-months{
  display:flex;
}

.rdp-month{
  margin:0 1em;
}

.rdp-month:first-child{
  margin-left:0;
}

.rdp-month:last-child{
  margin-right:0;
}

.rdp-table{
  margin:0;
  max-width:calc(var(--rdp-cell-size) * 7);
  border-collapse:collapse;
}

.rdp-with_weeknumber .rdp-table{
  max-width:calc(var(--rdp-cell-size) * 8);
  border-collapse:collapse;
}

.rdp-caption{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding:0;
  text-align:left;
}

.rdp-multiple_months .rdp-caption{
  position:relative;
  display:block;
  text-align:center;
}

.rdp-caption_dropdowns{
  position:relative;
  display:inline-flex;
}

.rdp-caption_label{
  position:relative;
  z-index:1;
  display:inline-flex;
  align-items:center;
  margin:0;
  padding:0 0.25em;
  white-space:nowrap;
  color:currentColor;
  border:0;
  border:2px solid transparent;
  font-family:inherit;
  font-size:var(--rdp-caption-font-size);
  font-weight:bold;
}

.rdp-nav{
  white-space:nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav{
  position:absolute;
  top:50%;
  left:0;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav{
  position:absolute;
  top:50%;
  right:0;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
}

.rdp-nav_button{
  display:inline-flex;
  align-items:center;
  justify-content:center;
  width:var(--rdp-cell-size);
  height:var(--rdp-cell-size);
  padding:0.25em;
  border-radius:100%;
}
.rdp-dropdown_year,
.rdp-dropdown_month{
  position:relative;
  display:inline-flex;
  align-items:center;
}

.rdp-dropdown{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  position:absolute;
  z-index:2;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  margin:0;
  padding:0;
  cursor:inherit;
  opacity:0;
  border:none;
  background-color:transparent;
  font-family:inherit;
  font-size:inherit;
  line-height:inherit;
}

.rdp-dropdown[disabled]{
  opacity:unset;
  color:unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label{
  background-color:var(--rdp-background-color);
  border:var(--rdp-outline);
  border-radius:6px;
}

.rdp-dropdown_icon{
  margin:0 0 0 5px;
}

.rdp-head{
  border:0;
}

.rdp-head_row,
.rdp-row{
  height:100%;
}

.rdp-head_cell{
  vertical-align:middle;
  font-size:0.75em;
  font-weight:700;
  text-align:center;
  height:100%;
  height:var(--rdp-cell-size);
  padding:0;
  text-transform:uppercase;
}

.rdp-tbody{
  border:0;
}

.rdp-tfoot{
  margin:0.5em;
}

.rdp-cell{
  width:var(--rdp-cell-size);
  height:100%;
  height:var(--rdp-cell-size);
  padding:0;
  text-align:center;
}

.rdp-weeknumber{
  font-size:0.75em;
}

.rdp-weeknumber,
.rdp-day{
  display:flex;
  overflow:hidden;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  width:var(--rdp-cell-size);
  max-width:var(--rdp-cell-size);
  height:var(--rdp-cell-size);
  margin:0;
  border:2px solid transparent;
  border-radius:100%;
}

.rdp-day_today:not(.rdp-day_outside){
  font-weight:bold;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover{
  color:white;
  opacity:1;
  background-color:var(--rdp-accent-color);
}

.rdp-day_outside{
  opacity:0.5;
}

.rdp-day_selected:focus-visible{
  outline:var(--rdp-outline);
  outline-offset:2px;
  z-index:1;
}

.rdp:not([dir=rtl]) .rdp-day_range_start:not(.rdp-day_range_end){
  border-top-right-radius:0;
  border-bottom-right-radius:0;
}

.rdp:not([dir=rtl]) .rdp-day_range_end:not(.rdp-day_range_start){
  border-top-left-radius:0;
  border-bottom-left-radius:0;
}

.rdp[dir=rtl] .rdp-day_range_start:not(.rdp-day_range_end){
  border-top-left-radius:0;
  border-bottom-left-radius:0;
}

.rdp[dir=rtl] .rdp-day_range_end:not(.rdp-day_range_start){
  border-top-right-radius:0;
  border-bottom-right-radius:0;
}

.rdp-day_range_end.rdp-day_range_start{
  border-radius:100%;
}

.rdp-day_range_middle{
  border-radius:0;
}


.bp5-datepicker-content .rdp{
  --rdp-cell-size:30px;
  --rdp-accent-color:$primary;
  --rdp-background-color:#ffffff;
  --rdp-accent-color-dark:$stocklio-purple2;
  --rdp-background-color-dark:#383e47;
  --rdp-outline:2px solid var(--rdp-accent-color);
  --rdp-outline-selected:2px solid rgba(0, 0, 0, 75%);
  margin:0;
  min-width:auto;
}
.bp5-datepicker .rdp{
  display:inline-block;
  min-width:210px;
  position:relative;
  vertical-align:top;
}
.bp5-datepicker .rdp:focus{
  outline:none;
}
.bp5-datepicker .rdp-month{
  display:flex;
  flex-direction:column;
  margin:0 5px;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp5-datepicker .rdp-month + .bp5-datepicker .rdp-month{
  margin-left:10px;
}
.bp5-datepicker .rdp-caption{
  border-bottom:solid 1px rgba(17, 20, 24, 0.15);
  padding-bottom:5px;
}
.bp5-datepicker .rdp-table{
  align-self:center;
}
.bp5-datepicker .rdp-head_cell{
  font-size:inherit;
  font-weight:600;
  padding-top:5px;
  text-decoration:none;
  text-transform:none;
}
.bp5-datepicker .rdp-weeknumber{
  color:#5f6b7c;
}
.bp5-datepicker .rdp-day{
  border-radius:2px;
}
.bp5-datepicker .rdp-day.rdp-day_outside{
  color:rgba(95, 107, 124, 0.6);
}
.bp5-datepicker .rdp-day.rdp-day_today{
  font-weight:400;
}
.bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):hover, .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):focus{
  background:rgba(143, 153, 168, 0.15);
  color:#1c2127;
}
.bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-datepicker .rdp-day.rdp-day_disabled{
  background:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp5-datepicker .rdp-day.rdp-day_selected{
  background-color:$primary;
  border-radius:2px;
  color:#ffffff;
}
.bp5-datepicker .rdp-day.rdp-day_selected:hover{
  background-color:$stocklio-purple2;
  color:#ffffff;
}
.bp5-datepicker .rdp-day.rdp-day_selected:active{
  background-color:$stocklio-purple1;
}
.bp5-datepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today{
  border:1px solid rgba(17, 20, 24, 0.15);
}
.bp5-datepicker.bp5-datepicker-reverse-month-and-year .rdp-caption_dropdowns{
  flex-direction:row-reverse;
}

.bp5-datepicker-content{
  align-items:center;
  display:flex;
  flex-direction:column;
  gap:5px;
}
.bp5-datepicker-content > .bp5-divider{
  margin:0;
  width:calc(100% - 10px);
}

.bp5-datepicker-month-select select, .bp5-datepicker-year-select select{
  font-weight:600;
  padding-left:5px;
  padding-right:16px;
}
.bp5-datepicker-month-select select + .bp5-icon, .bp5-datepicker-year-select select + .bp5-icon{
  right:2px;
}

.bp5-datepicker-footer{
  display:flex;
  justify-content:space-between;
  width:100%;
}

.bp5-dark .bp5-datepicker{
  background:#383e47;
}
.bp5-dark .bp5-datepicker .rdp-week-number{
  color:#abb3bf;
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_outside{
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):hover, .bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):focus{
  background:rgba(143, 153, 168, 0.15);
  color:#ffffff;
}
.bp5-dark .bp5-datepicker .rdp-day:not([disabled]):not(.rdp-day_selected):active{
  background:rgba(143, 153, 168, 0.3);
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected{
  background-color:$primary;
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected:hover{
  background-color:$stocklio-purple2;
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_selected:active{
  background-color:$stocklio-purple1;
}
.bp5-dark .bp5-datepicker .rdp-day.rdp-day_disabled{
  background:none;
  color:rgba(171, 179, 191, 0.6);
}
.bp5-dark .bp5-datepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today{
  border:1px solid rgba(255, 255, 255, 0.2);
}
.bp5-dark .bp5-datepicker .bp5-datepicker-footer{
  border-top-color:rgba(17, 20, 24, 0.4);
}

.bp5-datepicker-timepicker-wrapper{
  align-items:center;
  display:flex;
  flex-direction:column;
}
.bp5-datepicker-timepicker-wrapper .bp5-timepicker-arrow-row:empty + .bp5-timepicker-input-row{
  margin:5px 0;
}
.bp5-datepicker-caption.rdp-caption{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin:0;
}
.bp5-datepicker-caption.rdp-caption > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-datepicker-caption.rdp-caption > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-datepicker-caption.rdp-caption .bp5-html-select select{
  font-weight:600;
  padding-left:5px;
  padding-right:16px;
}
.bp5-datepicker-caption.rdp-caption .bp5-html-select select + .bp5-icon{
  right:2px;
}
.bp5-datepicker-caption.rdp-caption + .bp5-divider{
  margin:0;
}
.bp5-datepicker-caption.rdp-caption .bp5-datepicker-nav-button-hidden{
  visibility:hidden;
}

.bp5-datepicker-month-select{
  flex-shrink:1;
}

.bp5-datepicker-year-select{
  flex-shrink:1;
  min-width:60px;
}

.bp5-datepicker-caption-measure{
  font-weight:600;
  padding-left:5px;
}
.bp5-daterangepicker{
}
.bp5-daterangepicker.bp5-daterangepicker-contiguous .rdp{
  min-width:220px;
}
.bp5-daterangepicker.bp5-daterangepicker-single-month .rdp{
  min-width:210px;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption > *{
  flex-grow:0;
  flex-shrink:0;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption > .bp5-fill{
  flex-grow:1;
  flex-shrink:1;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_start .rdp-caption{
  flex-direction:row-reverse;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_start .rdp-caption::before{
  content:"";
  height:30px;
  width:30px;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_end .rdp-caption{
  flex-direction:row;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-caption_end .rdp-caption::before{
  content:"";
  height:30px;
  width:30px;
}
.bp5-daterangepicker .rdp.rdp-multiple_months .rdp-nav{
  left:initial;
  position:initial;
  top:initial;
  -webkit-transform:none;
          transform:none;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_outside{
  visibility:hidden;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered{
  border-radius:0;
  color:$stocklio-purple2;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered:not(.rdp-day_selected):not(.rdp-day_range_start):not(.rdp-day_range_middle):not(.rdp-day_range_end){
  background-color:rgba(45, 114, 210, 0.1);
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle{
  background-color:rgba(45, 114, 210, 0.1);
  border-radius:0;
  color:$stocklio-purple2;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle:hover{
  background-color:rgba(45, 114, 210, 0.2);
  color:$stocklio-purple2;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_range_start:not(.rdp-day_range_end):not(.rdp-day_hovered_end){
  border-bottom-right-radius:0;
  border-top-right-radius:0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_range_end:not(.rdp-day_range_start):not(.rdp_day_hovered_start){
  border-bottom-left-radius:0;
  border-top-left-radius:0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered_start:not(.rdp-day_hovered_end){
  border-bottom-right-radius:0;
  border-top-right-radius:0;
}
.bp5-daterangepicker.bp5-datepicker .rdp-day_hovered_end:not(.rdp-day_hovered_start){
  border-bottom-left-radius:0;
  border-top-left-radius:0;
}
.bp5-daterangepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today{
  border:1px solid rgba(17, 20, 24, 0.15);
}
.bp5-daterangepicker.bp5-daterangepicker-reverse-month-and-year.bp5-daterangepicker-contiguous .rdp-caption_dropdowns{
  flex-direction:row-reverse;
}

.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_hovered{
  color:#f6f7f9;
}
.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_hovered:not(.rdp-day_selected):not(.rdp-day_range_start):not(.rdp-day_range_middle):not(.rdp-day_range_end){
  background-color:rgba(45, 114, 210, 0.2);
}
.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle{
  background-color:rgba(45, 114, 210, 0.2);
  color:#f6f7f9;
}
.bp5-dark .bp5-daterangepicker.bp5-datepicker .rdp-day_range_middle:hover{
  background-color:rgba(45, 114, 210, 0.4);
}
.bp5-dark .bp5-daterangepicker.bp5-datepicker-highlight-current-day .rdp-day.rdp-day_today{
  border:1px solid rgba(255, 255, 255, 0.2);
}
/*# sourceMappingURL=blueprint-datetime2.css.map */