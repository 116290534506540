@import "~normalize.css";
@import "~@stockifi/shared/src/styles/variables.scss";

@import "~@stockifi/shared/src/styles/blueprint.scss";
@import "~@stockifi/shared/src/styles/blueprint-datetime.scss";
@import "~@stockifi/shared/src/styles/blueprint-datetime2.scss";

@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "react-perfect-scrollbar/dist/css/styles.css";

@import "~@stockifi/shared/src/styles/bootstrap.scss";
@import "./openreplay.scss";

html,
body,
#root,
#root > div {
  height: 100%;
  width: 100%;
  display: flex;
}

.w-100 {
  width: 100% !important;
}

.w-95 {
  width: 95% !important;
}

.w-95-alt {
  width: calc(95% - 40px) !important;
}

.max-h-100 {
  max-height: 100% !important;
}

/* Remove Arrows/Spinners - Hide Arrows From Input Number*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.docs-example {
  align-items: center;
  background: $example-background-color;
  border-radius: $example-frame-border-radius;
  // display: flex;
  // flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  // https://css-tricks.com/flexbox-truncated-text/#article-header-id-3
  min-width: 0;
  padding: $example-spacing * 0.2;

  .#{$ns}-dark & {
    background: $dark-example-background-color;
  }

  > * {
    margin: $example-spacing * 0.1;
  }
}
