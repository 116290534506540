.bp5-dark {
  background-color: #383e47;
}

.navbar-main {
  display: flex;
  flex-direction: column;
  height: unset;
  align-items: center;
  z-index: 9;
}

.navbar-header {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;

  @media only screen and (max-width: 960px) {
    grid-template-columns: 1fr 2fr 1fr;
    padding: 0;
  }

  @media only screen and (max-width: 640px) {
    grid-template-columns: 5fr 6fr 3fr;
    padding: 0;
  }
}

.navbar-header-right {
  justify-content: flex-end;
}

.offline_bottom_bar {
  position: fixed;
  bottom: 0;
  background-color: red;
  width: 100%;
  text-align: center;
  z-index: 99;
}

.offline_bottom_bar_text {
  font-size: 14px;
  padding: 2px;
  font-weight: 600;
}

.cog-btn-popover,
.inbox-btn-popover {
  z-index: 10;
}

.scroll-bar {
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
}

.split-button {
  position: fixed;
  bottom: 80px;
  right: 30px;
  z-index: 19;
}

.shortcut-guide-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 19;
}

.invoice-floating-buttons-toggle-button {
  position: fixed;
  bottom: 125px;
  right: 30px;
  z-index: 19;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gutter {
  background-repeat: no-repeat;
  background-position: 50%;
}

.no-drag .gutter {
  pointer-events: none;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: row-resize;
}
