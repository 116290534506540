$breakpoint-0: 540px;
$breakpoint-1: 900px;
$breakpoint-2: 964px;

.drawer-menu {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.active-font {
  color: #bb86fc !important;
}

.menu-divider-1 {
  display: none;
}

li:has(.menu-group-1),
.menu-divider-2 {
  display: none;
  @media only screen and (max-width: $breakpoint-0) {
    display: block;
  }
}

li:has(.menu-group-2),
.menu-divider-3 {
  display: none;
  @media only screen and (max-width: $breakpoint-1) {
    display: block;
  }
}

li:has(.menu-group-3),
.menu-divider-4 {
  display: none;
  @media only screen and (max-width: $breakpoint-2) {
    display: block;
  }
}
