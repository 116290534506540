// Current navigation breakpoints:
$breakpoint-0: 540px;
$breakpoint-1: 900px;
$breakpoint-2: 964px;

.nav-tabs {
  display: flex;
  flex-direction: row;
}

.nav-divider {
  height: 35%;
  border-color: hsla(0, 0%, 100%, 0.2) !important;
  margin-left: 0 !important;
  margin: 8px;
}

.tab-group-1,
.divider-group-1 {
  display: flex;

  &.tab-selected {
    display: flex;
  }

  @media only screen and (max-width: $breakpoint-0) {
    display: none;
  }
}

.tab-group-2,
.divider-group-2 {
  display: flex;

  &.tab-selected {
    display: flex;
  }
  @media only screen and (max-width: $breakpoint-1) {
    display: none;
  }
}

.tab-group-3,
.divider-group-3 {
  display: flex;

  &.tab-selected {
    display: flex;
  }

  @media only screen and (max-width: $breakpoint-2) {
    display: none;
  }
}

.tablet-nav-portal {
  display: flex;
  z-index: 20;
}
