.indicator {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;

  &__text {
    color: #ffc23d;
  }
}

@media (max-width: 960px) {
  .indicator__text {
    display: none;
  }
}
