.more_txt {
  white-space: nowrap;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}

.custom_types_input {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important; 

  & > *:first-child {
    flex-basis: 95% !important;
  }
}
