.row_value {
  padding: 0.5em 1em;
  display: inline-flex;
  border-radius: 30px;
  transition-property: background-color;
  transition-duration: 100ms;
  cursor: pointer;
  gap: 10px;
}
.row_value:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.row_value:hover .edit_icon {
  opacity: 1;
}

.edit_icon {
  transition-property: opacity;
  transition-duration: 100ms;
  opacity: 0;
}

.value {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

