iframe {
  width: 200%;
  height: 200%;

  //scale down contents of iframe up to 60% from original
  -ms-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.wrapper {
  overflow-y: auto;
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }
}

.translate {
  top: 0;
  height: 100%;
  display: flex;
  padding-bottom: 5px;
}

.images {
  bottom: 0;
}

.split {
  height: 100%;
  right: 0;
}
