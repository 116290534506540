.rootContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.textarea {
  resize: vertical;
  flex: 1;
  overflow-y: auto;
  min-height: 150px;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: transparent;
  }
}

.loading {
  margin-top: 20px;
}

div.textarea {
  line-height: 1.5;
}

.popover {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: transparent;
  }
}

.languageSelect {
  display: flex;
  align-items: center;

  h4 {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
