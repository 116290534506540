#openreplay-confirm-window-wrapper {
  background-color: rgba(17, 20, 24, 0.7);
}

#openreplay-confirm-window-buttons {
  background-color: #383e47 !important;
  box-shadow: none !important;
  padding: 0 !important;
  justify-content: center;
}
